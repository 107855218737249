/** @format */

// @ts-ignore
const env = import.meta.env;
const Environment = {
  INDEXER_URL: env.VITE_INDEXER_URL,
  PROVIDER_URL: env.VITE_PROVIDER_URL,
  PROVIDER_SOCKET: env.VITE_PROVIDER_SOCKET,
  API_HOST: env.VITE_API_HOST,
  WEB3AUTH_CLIENT_ID: env.VITE_WEB3AUTH_CLIENT_ID,
  CHAIN_ID: env.VITE_CHAIN_ID,
  WORKSHOP_NFT_ADDRESS: env.VITE_WORKSHOP_NFT_ADDRESS,
  RESOURCE_ADDRESS: env.VITE_RESOURCE_ADDRESS,
  WORLD_ADDRESS: env.VITE_WORLD_ADDRESS,
  WORLD_BLOCK_NUMBER: env.VITE_WORLD_BLOCK_NUMBER,
  MOAI_TOKEN_ADDRESS: env.VITE_MOAI_TOKEN_ADDRESS,
  GAME_SKU: env.VITE_GAME_SKU,
  MOAI_WORLD_ADDRESS: env.VITE_MOAI_WORLD_ADDRESS,
};
export default Environment;
