/** @format */

// /** @format */
// import Auth from "./auth";
// import Resource from "./resource-contract";
// let auth = new Auth();
// let resource = new Resource();
// async function init() {
//   await auth.initModal();
//   if (auth.isConnected()) {
//     return;
//   }
// }
// const bridge = { init, auth, resource };

import { BigNumber, ethers, getDefaultProvider } from "ethers";
import Auth from "./auth";
import MoaiToken from "./moai-token-contract";
import Resource from "./resource-contract";
import Workshop from "./workshop";
import World, { TowerData } from "./world";
import Environment from "./environment";
import { Map } from "./world";
import MoaiWorld from "./moai-world";
// export default bridge;
export default class Bridge {
  state: "idle" | "init_auth" | "connecting" | "indexing" | "ready" = "idle";
  auth: Auth = new Auth();
  resource: Resource = new Resource();
  moaiToken: MoaiToken = new MoaiToken();
  workshop: Workshop = new Workshop();
  world: World = new World();
  moaiWorld: MoaiWorld = new MoaiWorld();
  async init() {
    this.state = "init_auth";
    await this.auth.initModal();
    if (this.auth.isConnected()) {
      await this.onConnected();
    }
  }
  async connect() {
    this.state = "connecting";
    await this.auth.connect();
    if (this.auth.isConnected()) {
      console.log("connect");
      await this.onConnected();
    } else {
      console.log("not connect");
    }
  }

  isConnected() {
    return this.auth.isConnected;
  }
  getState() {
    return this.state;
  }
  async logout() {
    this.auth.logout();
  }

  async onConnected() {
    this.state = "indexing";
    this.resource.connectSigner(this.auth.getSigner());
    this.moaiToken.connectSigner(this.auth.getSigner());
    this.workshop.connectSigner(this.auth.getSigner());
    this.moaiWorld.connectSigner(this.auth.getSigner());
    await this.world.initWorld(this.auth.getSigner());
    while (true) {
      let ld: any = this.world.getLoadingState();
      console.log("loading", ld);
      if (ld.step === "live") {
        break;
      }
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(null);
        }, 500);
      });
    }
    console.log("world connected ", {
      user: this.auth.getAddress(),
      world: this.world.getWorldContract().address,
    });
    this.state = "ready";
  }

  // async createMap() {
  //   let fee = 10,
  //     rewardType = 2,
  //     cp = 3,
  //     size = 9,
  //     element = 2,
  //     difficult = 1;
  //   let terrains = Array(size * size).fill(0);
  //   let towers = [
  //     {
  //       dx: 1,
  //       dy: 2,
  //       towerType: 3,
  //       fire: 4,
  //       radius: 5,
  //     },
  //   ];
  //   let adminWallet = new ethers.Wallet(
  //     "0111bef17168d28d59210930d96e88689c3117c4cedfc18eb10b417d1898bb61",
  //     getDefaultProvider(Environment.PROVIDER_URL)
  //   );
  //   let signature = await adminWallet.signMessage(
  //     ethers.utils.arrayify(
  //       ethers.utils.solidityKeccak256(
  //         ["uint256", "uint256", "uint256", "uint256", "uint256"],
  //         [
  //           BigNumber.from(rewardType),
  //           BigNumber.from(cp),
  //           BigNumber.from(size),
  //           BigNumber.from(element),
  //           BigNumber.from(difficult),
  //         ]
  //       )
  //     )
  //   );
  //   await this.resource.approveWorldToUseResource();
  //   await this.world.createMap(
  //     fee,
  //     rewardType,
  //     cp,
  //     size,
  //     element,
  //     difficult,
  //     terrains,
  //     towers,
  //     signature
  //   );
  //   console.log("create map done");
  // }
  // async joinMap() {
  //   let enemyMap = this.world
  //     .getMaps()
  //     .filter(
  //       (m: Map) =>
  //         m.creator !== this.auth.getAddress() &&
  //         m.reward &&
  //         m.reward > 0 &&
  //         !(m.lockTime && m.lockTime > new Date())
  //     );
  //   console.log("all map ", enemyMap);
  //   let map = enemyMap.pop();
  //   if (map) {
  //     console.log("join map ", map);
  //     await this.resource.approveWorldToUseResource();
  //     await this.world.joinMap(map.id);
  //     console.log("join map done");
  //   } else {
  //     console.log("map not found");
  //   }
  // }
  // async setWinner() {
  //   let mapId = prompt("map id");
  //   //create map
  //   let adminWallet = new ethers.Wallet(
  //     "0111bef17168d28d59210930d96e88689c3117c4cedfc18eb10b417d1898bb61",
  //     getDefaultProvider(Environment.PROVIDER_URL)
  //   );
  //   // return console.log("admin", adminWallet);
  //   const MAP_ID = 1;
  //   let { abi, address } = this.world.getWorldContract();
  //   let ct = new ethers.Contract(address, abi).connect(adminWallet);
  //   console.log("set winner ", {
  //     ct,
  //     map: mapId,
  //     winner: this.auth.getAddress(),
  //   });
  //   let tx = await ct.setWinner(mapId, this.auth.getAddress());
  //   console.log("set winenr ok ", tx);
  // }
}
