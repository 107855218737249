/** @format */
// @ts-ignore: Object is possibly 'undefined'.
import { setup } from "./mud/setup";
import { singletonEntity, decodeEntity } from "@latticexyz/store-sync/recs";
import {
  runQuery,
  Has,
  getComponentValueStrict,
  HasValue,
  getComponentValue,
  getEntitySymbol,
} from "@latticexyz/recs";
import { hexToArray } from "@latticexyz/utils";
import { BigNumber, Wallet, ethers } from "ethers";
import config from "./config";
export interface StakePosition {
  owner: string;
  position: number;
  tokenId: number;
}
export interface Map {
  id: number;
  creator?: string;
  winner?: string;
  entryFee?: number;
  reward?: number;
  rewardType?: number;
  path?: number[];
  terrain?: number[];
  size?: number;
  element?: number;
  difficult?: number;
  cp?: number;
  lockTime?: Date | null;
  towers: Tower[];
}
export interface Tower {
  mapId: number;
  x?: number;
  y?: number;
  type?: number;
  fire?: number;
  radius?: number;
}
export default class World {
  constructor() {}
  stakePosition: StakePosition[] = [];
  maps: Map[] = [];
  mud: any = null;
  async initWorld(signer: Wallet) {
    this.mud = await setup(signer.privateKey);
    this.mud.components.Tower.update$.subscribe((update: any) => {
      const [nextValue, prevValue] = update.value;
      let { mapId, x, y } = decodeEntity(
        update.component.metadata.keySchema,
        update.entity
      );
      let newValue = {
        mapId: Number(mapId),
        x: Number(x),
        y: Number(y),
        towerType: Number(nextValue.towerType),
        fire: Number(nextValue.fire),
        radius: Number(nextValue.radius),
      };
      let pos = this.maps.findIndex((i: Map) => i.id === newValue.mapId);

      if (pos === -1) {
        this.maps.push({
          id: newValue.mapId,
          towers: [newValue],
        });
      } else {
        if (!this.maps[pos].towers) {
          this.maps[pos].towers = [];
        }
        let towerPos: number = this.maps[pos].towers.findIndex(
          (t: Tower) => t.x === newValue.x && t.y === newValue.y
        );
        if (towerPos === -1) {
          this.maps[pos].towers?.push(newValue);
        } else {
          this.maps[pos].towers![towerPos] = newValue;
        }
      }
    });
    this.mud.components.StakePosition.update$.subscribe((update: any) => {
      const [nextValue, prevValue] = update.value;
      if (!nextValue) {
        //destroy
        let prevTokenId = Number(prevValue.tokenId);
        let pos = this.stakePosition.findIndex(
          (i: StakePosition) => i.tokenId === prevTokenId
        );
        if (pos > -1) {
          this.stakePosition.splice(pos, 1);
        }
        return;
      }
      let tokenId = Number(nextValue.tokenId);
      let { owner, position } = decodeEntity(
        update.component.metadata.keySchema,
        update.entity
      );
      let newValue = {
        tokenId,
        owner: String(owner),
        position: Number(position),
      };
      let pos = this.stakePosition.findIndex(
        (i: StakePosition) => i.tokenId === newValue.tokenId
      );

      if (pos === -1) {
        this.stakePosition.push(newValue);
      } else {
        this.stakePosition[pos] = newValue;
      }
    });

    this.mud.components.MapInfo.update$.subscribe((update: any) => {
      const [nextValue] = update.value;
      let id = Number(update.entity);
      let lockTime = Number(nextValue.lockTime);
      let newValue: any = {
        id,
        winner: nextValue.winner,
        creator: nextValue.creator,
        entryFee: Number(ethers.utils.formatEther(nextValue.entryFee)),
        reward: Number(ethers.utils.formatEther(nextValue.reward)),
        rewardType: Number(nextValue.rewardType),
        path: [],
        lockTime:
          lockTime > 0
            ? new Date(lockTime * 1000 + config.LOCK_TIME * 1000)
            : null,
      };
      let pos = this.maps.findIndex((i: Map) => i.id === newValue.id);

      if (pos === -1) {
        newValue.towers = [];
        this.maps.push(newValue);
      } else {
        this.maps[pos] = Object.assign(this.maps[pos], newValue);
      }
    });
    this.mud.components.MapSetting.update$.subscribe((update: any) => {
      const [nextValue] = update.value;
      let id = Number(update.entity);
      let newValue: any = {
        id,
        cp: Number(nextValue.cpIndex),
        size: Number(nextValue.size),
        element: Number(nextValue.element),
        difficult: Number(nextValue.difficult),
        terrain: Array.from(hexToArray(nextValue.terrain)),
        path: Array.from(hexToArray(nextValue.path)),
      };
      let pos = this.maps.findIndex((i: Map) => i.id === newValue.id);

      if (pos === -1) {
        newValue.towers = [];
        this.maps.push(newValue);
      } else {
        this.maps[pos] = Object.assign(this.maps[pos], newValue);
      }
    });
  }
  getLoadingState() {
    let loadingState = getComponentValue(
      this.mud.components.SyncProgress,
      singletonEntity
    );
    return loadingState;
  }
  async stake(tokenId: number, position: number) {
    await this.mud.systemCalls.stake(tokenId, position);
  }
  async unstake(tokenId: number) {
    await this.mud.systemCalls.unStake(tokenId);
  }
  async test() {
    await this.mud.systemCalls.test();
  }
  getStakePosition() {
    return this.stakePosition;
  }
  getMaps() {
    return this.maps;
  }
  getWorldContract() {
    return this.mud.network.worldContract;
  }

  async createMap(
    fee: number,
    rewardType: number,
    cp: number,
    size: number,
    element: number,
    difficult: number,
    path: number[],
    terrains: number[],
    towers: TowerData[],
    signature: string
  ) {
    await this.mud.systemCalls.createMap(
      fee,
      [rewardType, cp, size, element, difficult],
      terrains,
      path,
      towers,
      signature
    );
  }
  async joinMap(mapId: number) {
    await this.mud.systemCalls.join(mapId);
  }
}
export interface TowerData {
  dx: number;
  dy: number;
  towerType: number;
  fire: number;
  radius: number;
}
