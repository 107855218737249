/** @format */
import { ethers } from "ethers";
import Environment from "./environment";
import ContractBase from "./contract-base";
import RESOURCE_ABI from "./abi/Resource";
import MOAI_WORLD_ABI from "./abi/World";
export default class MoaiWorld extends ContractBase {
  constructor() {
    super(MOAI_WORLD_ABI, Environment.MOAI_WORLD_ADDRESS);
  }
  async craftNFT(type: number, rarity: number) {
    try {
      console.log("crafting nft ", {
        type,
        rarity,
        GAME_ADDRESS: Environment.GAME_SKU,
      });

      await this.contract.nftCraft(Environment.GAME_SKU, type, rarity);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
