/** @format */

import { ethers } from "ethers";
import Environment from "./environment";
import utils from "./util";
let configData: any = null;
const config = {
  LOCK_TIME: 300, //seconds to lock map
  web3auth: {
    clientId: Environment.WEB3AUTH_CLIENT_ID,
    chainConfig: {
      chainNamespace: "eip155",
      chainId: ethers.utils.hexlify(Number(Environment.CHAIN_ID)),
      rpcTarget: Environment.PROVIDER_URL,
    },
  },
};
export default config;
