import { mudConfig } from "@latticexyz/world/register";

export default mudConfig({
  enums: {
    RewardType: ["NONE", "FIRE", "EARTH", "AIR", "WATER", "LIGHT"],
    TowerType: ["FIRE", "EARTH", "AIR", "WATER", "LIGHT"],
    ContractType: ["MOAI", "LAND"],
    TerrainType: ["None", "TallGrass", "Boulder"],
  },
  tables: {
    ContractConfig: {
      keySchema: {},
      dataStruct: false,
      valueSchema: {
        admin: "address",
        resourceToken: "address",
        workShopNft: "address",
      },
    },
    Player: {
      keySchema: {
        player: "bytes32",
      },
      valueSchema: {
        slot: "uint32",
        fire: "uint32",
        earth: "uint32",
        air: "uint32",
        water: "uint32",
        light: "uint32",
      },
    },
    MapCounter: {
      keySchema: {},
      valueSchema: "uint256",
    },
    Map: "uint256",
    MapInfo: {
      keySchema: {
        id: "uint256",
      },
      valueSchema: {
        creator: "address",
        winner: "address",
        entryFee: "uint256",
        reward: "uint256",
        rewardType: "uint256",
        lockTime: "uint256",
      },
    },
    MapSetting: {
      keySchema: {
        id: "uint256",
      },
      valueSchema: {
        cpIndex: "uint256",
        size: "uint256",
        element: "uint256",
        difficult: "uint256",
        path: "bytes",
        terrain: "bytes",
      },
    },
    PlayerMaps: {
      keySchema: {
        player: "bytes32",
      },
      valueSchema: {
        ids: "uint256[]", //map aray index
      },
    },
    PlayerHistory: {
      keySchema: {
        player: "address",
        mapId: "uint256",
      },
      valueSchema: {
        rewardType: "uint256",
        reward: "uint256",
        deposit: "uint256",
        owner: "bool",
        won: "bool",
      },
    },
    Tower: {
      keySchema: {
        mapId: "uint256",
        x: "uint32",
        y: "uint32",
      },
      valueSchema: {
        dx: "uint32",
        dy: "uint32",
        towerType: "uint32",
        fire: "uint32",
        radius: "uint32",
      },
    },
    Obstruction: "bool",
    Position: {
      dataStruct: false,
      valueSchema: {
        mapId: "uint256",
        x: "uint32",
        y: "uint32",
      },
    },
    EncounterTrigger: "bool",
    StatuePart: {
      keySchema: {
        rarity: "uint32",
      },
      valueSchema: {
        powerPoint: "uint32",
        costAmount: "uint32",
      },
    },
    LandStake: {
      keySchema: {
        tokenId: "uint256",
      },
      valueSchema: {
        owner: "address",
      },
    },
    StakePosition: {
      keySchema: {
        owner: "address",
        position: "uint32",
      },
      valueSchema: {
        tokenId: "uint256",
      },
    },
  },
});
