/** @format */

import { ethers, Wallet, BaseContract, Contract } from "ethers";
import Environment from "./environment";

export default class ContractBase {
  contract: Contract;
  signer: Wallet | null = null;
  constructor(abi: any, address: string) {
    this.contract = new Contract(address, abi);
    console.log("construct contract ", { ct: this.contract, address, abi });
  }
  connectSigner(signer: Wallet) {
    this.signer = signer;
    this.contract = this.contract.connect(signer);
  }
}
