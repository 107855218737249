/** @format */
import { ethers } from "ethers";
import Environment from "./environment";
import ContractBase from "./contract-base";
import MOAI_TOKEN_ABI from "./abi/MoaiToken";
export default class MoaiToken extends ContractBase {
  constructor() {
    super(MOAI_TOKEN_ABI, Environment.MOAI_TOKEN_ADDRESS);
  }
  async getBalance(address: string) {
    let rs = await this.contract.balanceOf(address);
    return Number(ethers.utils.formatEther(rs));
  }
}
