/** @format */
import Bridge from "./bridge";
import bridge from "./bridge";
import { setup } from "./mud/setup";

// const {
//   components,
//   systemCalls: { increment },
//   network,
// } = await setup();
// console.log("comp", components);
// Components expose a stream that triggers when the component is updated.
// components.Counter.update$.subscribe((update) => {
//   const [nextValue, prevValue] = update.value;
//   console.log("Counter updated", update, { nextValue, prevValue });
//   document.getElementById("counter")!.innerHTML = String(nextValue?.value ?? "unset");
// });

// Just for demonstration purposes: we create a global function that can be
// called to invoke the Increment system contract via the world. (See IncrementSystem.sol.)
console.log("set window ", window);

(window as any).bridge = new Bridge();
// https://vitejs.dev/guide/env-and-mode.html
// if (import.meta.env.DEV) {
//   const { mount: mountDevTools } = await import("@latticexyz/dev-tools");
//   mountDevTools({
//     config: mudConfig,
//     publicClient: network.publicClient,
//     walletClient: network.walletClient,
//     latestBlock$: network.latestBlock$,
//     storedBlockLogs$: network.storedBlockLogs$,
//     worldAddress: network.worldContract.address,
//     worldAbi: network.worldContract.abi,
//     write$: network.write$,
//     recsWorld: network.world,
//   });
// }
