/** @format */
import Environment from "./environment";
import ContractBase from "./contract-base";
import WORKSHOP_NFT_ABI from "./abi/Workshop";
import Resource from "./resource-contract";
import { Wallet } from "ethers";
export default class Workshop extends ContractBase {
  constructor() {
    super(WORKSHOP_NFT_ABI, Environment.WORKSHOP_NFT_ADDRESS);
  }
  connectSigner(signer: Wallet): void {
    super.connectSigner(signer);
  }
  async getBalance(address: string) {
    let total = Number(await this.contract.balanceOf(address));
    return total;
  }
  async getNFTs(address: string) {
    let total = await this.getBalance(address);
    let rs = (
      await Promise.all(
        Array(total)
          .fill(0)
          .map((_: any, index: number) =>
            this.contract.tokenOfOwnerByIndex(address, index)
          )
      )
    ).map((i: any) => Number(i));
    return rs;
  }

  async approveWorldToUseNFT() {
    let isApprove = await this.contract.isApprovedForAll(
      this.signer?.address,
      Environment.WORLD_ADDRESS
    );
    if (!isApprove) {
      console.log("approve world to use workshop nft ", {
        address: this.signer?.address,
        world: Environment.WORLD_ADDRESS,
      });
      await this.contract.setApprovalForAll(Environment.WORLD_ADDRESS, true);
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(null);
        }, 3000);
      });
    } else {
      console.log("world already approved");
    }
  }
}
