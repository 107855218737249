/**
 * Create the system calls that the client can use to ask
 * for changes in the World state (using the System contracts).
 *
 * @format
 */

import { getComponentValue } from "@latticexyz/recs";
import { ClientComponents } from "./createClientComponents";
import { SetupNetworkResult } from "./setupNetwork";
import { singletonEntity } from "@latticexyz/store-sync/recs";
import { BigNumber, ethers, getDefaultProvider } from "ethers";
import Environment from "../environment";
import { TowerData } from "../world";
export type SystemCalls = ReturnType<typeof createSystemCalls>;
const provider = getDefaultProvider(Environment.PROVIDER_URL);
export function createSystemCalls(
  /*
   * The parameter list informs TypeScript that:
   *
   * - The first parameter is expected to be a
   *   SetupNetworkResult, as defined in setupNetwork.ts
   *
   * - Out of this parameter, we only care about two fields:
   *   - worldContract (which comes from getContract, see
   *     https://github.com/latticexyz/mud/blob/26dabb34321eedff7a43f3fcb46da4f3f5ba3708/templates/vanilla/packages/client/src/mud/setupNetwork.ts#L31).
   *   - waitForTransaction (which comes from syncToRecs, see
   *     https://github.com/latticexyz/mud/blob/26dabb34321eedff7a43f3fcb46da4f3f5ba3708/templates/vanilla/packages/client/src/mud/setupNetwork.ts#L39).
   *
   * - From the second parameter, which is a ClientComponent,
   *   we only care about Counter. This parameter comes to use
   *   through createClientComponents.ts, but it originates in
   *   syncToRecs 
(https://github.com/latticexyz/mud/blob/26dabb34321eedff7a43f3fcb46da4f3f5ba3708/templates/vanilla/packages/client/src/mud/setupNetwork.ts#L39).
   */
  { worldContract, waitForTransaction, playerEntity }: SetupNetworkResult,
  { Counter }: ClientComponents
) {
  const execute = async (fn: string, params: any[]) => {
    console.log(`calling ${fn}`, params);
    if (!playerEntity) {
      throw new Error("no player");
    }
    try {
      // @ts-ignore
      const tx = await worldContract.write[fn](params);
      console.log(`sent [${fn}] [${tx}]`);
      let rs = await waitForTransaction(tx);
      console.log(`done [${fn}] [${tx}]`, rs);
    } catch (err: any) {
      console.error("write error ", err);
      throw err;
    } finally {
    }
  };
  const read = async (fn: string, params: any[]) => {
    console.log(`reading ${fn} ${JSON.stringify(params)}`);
    if (!playerEntity) {
      throw new Error("no player");
    }
    try {
      // @ts-ignore
      const rs = await worldContract.read[fn](params);
      console.log("read ", rs);
      return rs;
    } catch (err: any) {
      throw err;
    } finally {
    }
  };
  const stake = async (tokenId: number, position: number) => {
    await execute("stake", [tokenId, position]);
  };
  const unStake = async (tokenId: number) => {
    await execute("unStake", [tokenId]);
  };
  const createMap = async (
    fee: number,
    datas: number[],
    terrains: number[],
    path: number[],
    towers: TowerData[],
    signature: Uint8Array
  ) => {
    console.log("create map ", { fee, datas, terrains, towers, signature });
    await execute("createMap", [
      ethers.utils.parseUnits(String(fee)),
      datas,
      ethers.utils.hexlify(terrains),
      ethers.utils.hexlify(path),
      towers,
      signature,
    ]);
  };
  const join = async (mapId: number) => {
    await execute("join", [mapId]);
  };
  const test = async () => {
    await execute("stake", [14, 1]);
  };
  return { stake, test, createMap, unStake, join };
}
