/** @format */
import { ethers } from "ethers";
import Environment from "./environment";
import ContractBase from "./contract-base";
import RESOURCE_ABI from "./abi/Resource";
export default class Resource extends ContractBase {
  constructor() {
    super(RESOURCE_ABI, Environment.RESOURCE_ADDRESS);
  }
  async getBalance(address: string) {
    console.log("get balance ", address);
    let rs = await this.contract.balanceOfBatch(
      [address, address, address, address, address],
      [1, 2, 3, 4, 5]
    );
    return rs.map((balance: bigint) =>
      Number(ethers.utils.formatEther(balance))
    );
  }
  async approveWorldToUseResource() {
    let isApprove = await this.contract.isApprovedForAll(
      this.signer?.address,
      Environment.WORLD_ADDRESS
    );
    if (!isApprove) {
      console.log("approve world to use resource ", {
        address: this.signer?.address,
        world: Environment.WORLD_ADDRESS,
      });
      await this.contract.setApprovalForAll(Environment.WORLD_ADDRESS, true);
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(null);
        }, 3000);
      });
    }
    isApprove = await this.contract.isApprovedForAll(
      this.signer?.address,
      Environment.MOAI_WORLD_ADDRESS
    );
    if (!isApprove) {
      console.log("approve moai world to use resource ", {
        address: this.signer?.address,
        world: Environment.MOAI_WORLD_ADDRESS,
      });
      await this.contract.setApprovalForAll(
        Environment.MOAI_WORLD_ADDRESS,
        true
      );
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(null);
        }, 3000);
      });
    }
  }
}
