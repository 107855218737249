/** @format */
import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import config from "./config";
import { Wallet, getDefaultProvider } from "ethers";
import Environment from "./environment";
console.log("adapter ", WALLET_ADAPTERS);
export default class Auth {
  private web3auth: Web3Auth = new Web3Auth({
    clientId: config.web3auth.clientId,
    // @ts-ignore
    chainConfig: config.web3auth.chainConfig,
    // web3AuthNetwork: "sapphire_mainnet",
  });
  private signer: Wallet = Wallet.createRandom();
  constructor() {
    console.log("chainConfig ", config.web3auth);
  }
  async initModal() {
    if (this.web3auth.status === "ready") {
      return;
    }
    await this.web3auth.initModal({
      modalConfig: {
        // Disable Wallet Connect V2
        [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
          label: "wallet_connect",
          showOnModal: false,
        },
        // Disable Metamask
        [WALLET_ADAPTERS.METAMASK]: {
          label: "metamask",
          showOnModal: false,
        },
      },
    });
    console.log("init modal ", {
      // Disable Wallet Connect V2
      [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
        label: "wallet_connect",
        showOnModal: false,
      },
      // Disable Metamask
      [WALLET_ADAPTERS.METAMASK]: {
        label: "metamask",
        showOnModal: false,
      },
      [WALLET_ADAPTERS.TORUS_EVM]: {
        label: "torus_evm",
        showOnModal: false,
      },
      [WALLET_ADAPTERS.TORUS_SOLANA]: {
        label: "torus_solana",
        showOnModal: false,
      },
    });
    if (this.isConnected()) {
      await this.createSigner();
    }
  }
  isConnected() {
    return this.web3auth.connected;
  }
  getSigner() {
    if (!this.signer) {
      throw new Error("signer_not_set");
    }
    return this.signer;
  }
  getAddress() {
    return this.signer.address;
  }
  async connect() {
    await this.web3auth.connect();
    await this.createSigner();
  }
  async createSigner() {
    let pk = await this.getPrivateKey();
    this.signer = new Wallet(pk, getDefaultProvider(Environment.PROVIDER_URL));
  }
  async signMessage(msg: string): Promise<string> {
    if (!this.signer) {
      throw new Error("signer_not_found");
    }
    let signature = await this.signer.signMessage(msg);
    return signature;
  }
  async getPrivateKey() {
    if (this.web3auth.provider) {
      let pk = await this.web3auth.provider.request({
        method: "eth_private_key",
      });
      return `0x${pk}`;
    }
    throw new Error("provider_not_found");
  }
  async logout() {
    return this.web3auth.logout();
  }
}
